import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Alert, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import './css/Login.css';
import Swal from 'sweetalert2';
import img1 from './images/icon_login.png';
import img2 from './images/login_icon.png';

function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [showRegisterModal, setShowRegisterModal] = useState(false);
    const [registerUsername, setRegisterUsername] = useState("");
    const [registerCompany, setRegisterCompany] = useState("");
    const [registerPassword, setRegisterPassword] = useState("");
    const [registerPassword2, setRegisterPassword2] = useState("");
    const [registerHandphone, setRegisterHandphone] = useState("");
    const [registerTipeMitra, setregisterTipeMitra] = useState("");
    const [registerKategoriMitra, setregisterKategoriMitra] = useState("");
    const [registerError, setRegisterError] = useState("");
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        const isLoggedIn = sessionStorage.getItem("username");
        if (isLoggedIn) {
            navigate("/");
            //window.location.href = `${process.env.REACT_APP_FRONTEND}`;
        }
    }, [navigate]);

    const handleClick = () => {
        setShowRegisterModal(true);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/login`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            //console.log(data); // Log the response data for debugging

            if (data.success) {
                sessionStorage.setItem("username", data.username);
                sessionStorage.setItem("perusahaan", data.perusahaan);
                sessionStorage.setItem("user_id", data.user_id);
                sessionStorage.setItem("tipe", data.tipe);
                window.location.href = `${process.env.REACT_APP_FRONTEND}`;
            } else {
                setError(data.message || "Invalid credentials");
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
            setError("An error occurred. Please try again.");
        }
    };

    const handleRegisterSubmit = async (e) => {
        e.preventDefault();

        if (
            !registerHandphone.startsWith('0852') &&
            !registerHandphone.startsWith('0853') &&
            !registerHandphone.startsWith('0811') &&
            !registerHandphone.startsWith('0812') &&
            !registerHandphone.startsWith('0813') &&
            !registerHandphone.startsWith('0821') &&
            !registerHandphone.startsWith('0822') &&
            !registerHandphone.startsWith('0823') &&
            !registerHandphone.startsWith('0851')
        ) {
            Swal.fire({
                icon: 'warning',
                title: 'Peringatan',
                text: `NO HP PIC Harus menggunakan nomor TELKOMSEL!`,
            });
            return; // Stop form submission
        }


        if (registerPassword !== registerPassword2) {
            setRegisterError("Passwords do not match");
            return;
        }

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/register`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: registerUsername,
                    no_hp: registerHandphone,
                    perusahaan: registerCompany,
                    password: registerPassword,
                    tipe: registerTipeMitra,
                    kategori_mitra: registerKategoriMitra,
                }),
            });

            const data = await response.json();

            if (data.success) {
                setShowRegisterModal(false);
                alert('Registrasi Berhasil!! status anda masih menunggu approval dari admin untuk dapat login');
            } else {
                setRegisterError(data.message || "Registration failed");
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
            setRegisterError("An error occurred. Please try again.");
        }
    };

    return (
        <Container className="vh-100 d-flex justify-content-center align-items-center">
            <Row style={{ display: "flex", alignItems: "center" }}>
                <Col
                    xs={12}
                    md={7}
                    className="text-center justify-content-center p-100"
                    style={{ padding: "30px" }}
                >
                    <img
                        src={img2}
                        alt="login-logo"
                        style={{ width: "80%", height: "auto", paddingTop: "50px" }}
                    />
                </Col>
                <Col
                    xs={12}
                    md={5}
                    className="justify-content-top"
                    style={{ padding: "30px" }}
                >
                    <img src={img1} alt="Login Icon" className="mb-4" />

                    {error && (
                        <Alert variant="danger" style={{ padding: "10px", fontWeight: "bold" }}>
                            {error}
                        </Alert>
                    )}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>
                                <span style={{ fontWeight: "bold", color: "#999" }}>Email</span>
                            </Form.Label>
                            <Form.Control
                                required
                                type="email"
                                name="username"
                                placeholder="email_anda@mail.com"
                                onChange={(e) => setUsername(e.target.value)}
                                style={{ padding: "12px", fontSize: "18px" }}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label>
                                <span style={{ fontWeight: "bold", color: "#999" }}>
                                    Password
                                </span>
                            </Form.Label>
                            <Form.Control
                                type="password"
                                name="password"
                                required
                                placeholder="******"
                                onChange={(e) => setPassword(e.target.value)}
                                style={{ padding: "12px", fontSize: "18px" }}
                            />
                        </Form.Group>

                        <Button
                            type="submit"
                            className="custom-button mt-2"
                        >
                            Login
                        </Button>

                         <div>
                        <span onClick={handleClick} style={{ cursor: 'pointer' }}>
                            Don't Have Account?
                            <b> Create Account</b>
                        </span>

                        {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}

            {showRegisterModal && <div>Modal Register</div>}
        </div>
                        
                    </Form>
                </Col>
            </Row>

            <Modal show={showRegisterModal} onHide={() => setShowRegisterModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Registration Form</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{ fontSize: '13px' }} className="p-5">
                    {registerError && (
                        <Alert variant="danger" style={{ padding: "10px", fontWeight: "bold" }}>
                            {registerError}
                        </Alert>
                    )}
                    <Form onSubmit={handleRegisterSubmit}>

                        <Form.Group className="mb-3" controlId="registerTipeMitra">
                            <Form.Label>Tipe Mitra</Form.Label>
                            <Form.Select size="md" name="tipemitra" value={registerTipeMitra} onChange={(e) => setregisterTipeMitra(e.target.value)} required style={{ width: '100%' } } >
                                <option value="" disabled>Pilih Tipe Mitra</option>
                                <option value="2">Aggregator</option>
                                <option value="3">Aggregator & Switching</option>
                                <option value="4">Switching</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="registerUsername">
                            <Form.Label>Email</Form.Label>
                            <Form.Control
                                type="email"
                                placeholder="Masukkan Email (email perusahaan)"
                                value={registerUsername}
                                onChange={(e) => setRegisterUsername(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="registerHandphone">
                            <Form.Label>No Handphone</Form.Label>
                            <Form.Control
                                type="number"
                                placeholder="Masukkan nomor HP (nomor telkomsel)"
                                value={registerHandphone}
                                onChange={(e) => setRegisterHandphone(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="registerCompany">
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Masukkan nama perusahaan"
                                value={registerCompany}
                                onChange={(e) => setRegisterCompany(e.target.value)}
                                required
                            />
                        </Form.Group>

                        
                        <Form.Group className="mb-3" controlId="registerKategoriMitra">
                            <Form.Label>Kategori Mitra</Form.Label>
                            <Form.Select size="md" name="kategorimitra" value={registerKategoriMitra} onChange={(e) => setregisterKategoriMitra(e.target.value)} required style={{ width: '100%' } } >
                                <option value="" disabled>Pilih Kategori Mitra</option>
                                <option value="EKSISTING">EKSISTING</option>
                                <option value="BARU">BARU</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="registerPassword">
                            <Form.Label>Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Masukkan Password"
                                value={registerPassword}
                                onChange={(e) => setRegisterPassword(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="registerPassword2">
                            <Form.Label>Re-type Password</Form.Label>
                            <Form.Control
                                type="password"
                                placeholder="Konfirmasi Password"
                                value={registerPassword2}
                                onChange={(e) => setRegisterPassword2(e.target.value)}
                                required
                            />
                        </Form.Group>

                        <div className="text-end mb-4">
                            <Button variant="danger" type="submit">
                                Register
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>
        </Container>
    );
}

export default LoginPage;
