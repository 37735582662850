import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Alert, Form, Button, Row, Col, Container } from 'react-bootstrap';

const MAX_FILE_SIZE_MB = 10; // Maximum file size in MB

const FormDokumenSwitching_2 = ({ uid, username }) => {
    const [formData, setFormData] = useState({
        pentest: null,
        iso_27001: null,
        no_suspect: null,
        success_rate: null,
        monitoring_transaksi: null,
        it_support: null,
        contact_center: null,
        standard_resi: null,
        sn_struk: null,
        standard_sn: null,
        contact_center_resi: null,
        support_dev: null,
        stress_test: null
    });

    const [loading, setLoading] = useState(false);
    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getDokumenSwitching2`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                setIsFileRequired(false);
                if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                    setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, files } = e.target;

        if ((name === 'pentest' || name === 'iso_27001' || name === 'no_suspect' || name === 'success_rate' || name === 'monitoring_transaksi' || name === 'it_support' || name === 'contact_center' || name === 'standard_resi' || name === 'sn_struk' || name === 'standard_sn' 
            || name === 'contact_center_resi' || name === 'support_dev' || name === 'stress_test' ) && files.length > 0) {
            const file = files[0];
            if (file.type !== 'application/pdf') {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: 'Hanya file .pdf yang diperbolehkan!',
                });
                return;
            }
            if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Peringatan',
                    text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                });
                return;
            }

            setFormData((prevData) => ({
                ...prevData,
                [name]: file,
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFiles = [
            'pentest',
            'iso_27001',
            'no_suspect',
            'success_rate',
            'monitoring_transaksi',
            'it_support',
            'contact_center',
            'standard_resi',
            'sn_struk',
            'standard_sn',
            'contact_center_resi',
        ];

        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File tidak lengkap',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return;
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const data = new FormData();
        data.append('username', username);

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                const renamedFile = new File([formData[key]], `${usernamePart}_${key}.pdf`, {
                    type: formData[key].type,
                });
                data.append(key, renamedFile);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadDokumenSwitching_2`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Data berhasil disimpan',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal menyimpan data',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };
    
    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>QUALITY MANAGEMENT</b></p>      
            <Alert key="info" variant="info">Semua file yang di upload harus berformat <b>.pdf</b> dengan max size per file 10Mb</Alert>      
            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '1px solid #CCC' }} className="pb-4 mb-4">
                <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>PENTEST (SECURITY) <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="pentest" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>ISO 27001 <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="iso_27001" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>TIDAK ADA TRANSAKSI SUSPECT <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="no_suspect" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>IT SUPPORT 24/7 <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="it_support" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>CONTACT CENTER 24/7 <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="contact_center" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>STANDARISASI RESI/STRUK <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="standard_resi" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        <Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>ORIGINAL SN TSEL PADA STRUK <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="sn_struk" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>STANDARD  PENAMAAN SN <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="standard_sn" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>CONTACT CENTER MITRA PADA RESI/STRUK <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="contact_center_resi" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                    </Row>
                    <Row className='pb-2'>
                        {/*<Col xs={4} className="px-0">
                            <span style={{ fontSize: '12px' }}>SUPPORT PROGRAM DEVELOPMENT <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="support_dev" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>*/}
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>SUCCESS RATE <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="success_rate" onChange={handleChange}disabled={disabledForm} />
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>SYSTEM MONITORING TRANSAKSI <span className="text-danger"><b>*</b></span></span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} required={IsFileRequired} name="monitoring_transaksi" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={4} className="p-0">
                            <span style={{ fontSize: '12px' }}>STRESS TEST </span>
                            <Form.Control size="sm" type="file" style={{ width: '95%' }} name="stress_test" onChange={handleChange} disabled={disabledForm}/>
                            <p className="mt-1 mx-2" style={{ fontSize: '11px', color: '#999' }}>Max: 10 MB | file .PDF</p>
                        </Col>
                        <Col xs={12} className="pt-4 px-4 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    );
}

export default FormDokumenSwitching_2;
