import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ListGroup from 'react-bootstrap/ListGroup';
import { Modal, Form, Button, Alert } from "react-bootstrap";
import Fade from 'react-bootstrap/Fade'; // Import Fade component
import { CSSTransition, TransitionGroup } from 'react-transition-group'; // Import CSSTransition and TransitionGroup
import './css/animations.css'; // Import custom animations

import FormInformasiPerusahaan from './FormInformasiPerusahaan';
import FormKeanggotaanPerusahaan from './FormKeanggotaanPerusahaan';
import FormFilePerusahaan from './FormFilePerusahaan';
import FormLaporanTigaTahun from './FormLaporanTigaTahun';
import FormDokumenPengajuan from './FormDokumenPengajuan';
import FormDokumenSwitching_1 from './FormDokumenSwitching_1';
import FormDokumenSwitching_2 from './FormDokumenSwitching_2';
import FormDokumenSwitching_3 from './FormDokumenSwitching_3';
import FormReview from './FormReview';

const FormPengajuan = ({uid, tipe, username, nama_perusahaan}) => {
    const [activeSection, setActiveSection] = useState('0');
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showConsentModal, setshowConsentModal] = useState(false);
    const [registerError, setRegisterError] = useState("");

    useEffect(() => {
        setShow(true); // Start animation when component is mounted
        fetch(`${process.env.REACT_APP_SERVER}/getConsent`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            if(data[0].consent=='0'){
                setshowConsentModal(true); // Menjadikan Logo tidak wajib jika sudah ada
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleConsentSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${process.env.REACT_APP_SERVER}/submitConsent`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    username: username,
                }),
            });

            const data = await response.json();

            if (data.success) {
                setshowConsentModal(false);
            } else {
                setRegisterError(data.message || "Unexpected Error");
            }
        } catch (error) {
            console.error("Terjadi kesalahan:", error);
            setRegisterError("An error occurred. Please try again.");
        }
    };
   
    const renderSection = () => {
        switch (activeSection) {
            case '1': return (<FormInformasiPerusahaan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '2': return (<FormKeanggotaanPerusahaan uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '3': return (<FormFilePerusahaan uid={uid}tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '4': return (<FormLaporanTigaTahun uid={uid} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '5': return (<FormDokumenSwitching_3 uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '6': return (<FormDokumenSwitching_1 uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '7': return (<FormDokumenSwitching_2 uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '8': return (<FormDokumenPengajuan uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            case '0': return (<FormReview uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
            default:
                return (<FormReview uid={uid} tipe={tipe} username={username} nama_perusahaan={nama_perusahaan}/>);
        }
    };

    return (
        <div className="m-4 p-4 rounded-3 vh-100" style={{ backgroundColor: '#FFF' }}>
            <Fade in={show} timeout={300}>
                <header className={`mb-4 ${show ? 'slide-up-enter-active' : 'slide-up-enter'}`}>
                    <h5>Formulir Pengajuan</h5>
                    Segera lengkapi data perusahaan anda agar dapat mengikuti proses selanjutnya
                </header>
            </Fade>

            <Container>
                <Row>
                    <Col xs={3} className="pt-3">
                        <TransitionGroup>
                            <CSSTransition
                                in={show}
                                timeout={300}
                                classNames="slide-up"
                                unmountOnExit
                            >
                                <ListGroup variant="flush">
                                        <ListGroup.Item
                                        action onClick={() => setActiveSection('1')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '1' ? 'red' : 'transparent', color: activeSection === '1' ? 'white' : 'black' }}
                                    >
                                        Informasi Perusahaan
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('2')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '2' ? 'red' : 'transparent', color: activeSection === '2' ? 'white' : 'black' }}
                                    >
                                        Keanggotaan Perusahaan
                                    </ListGroup.Item>
                                    {tipe==1 || tipe==2 || tipe==3 ? (
                                    <><ListGroup.Item
                                        action onClick={() => setActiveSection('3')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '3' ? 'red' : 'transparent', color: activeSection === '3' ? 'white' : 'black' }}
                                    >
                                        File Perusahaan
                                    </ListGroup.Item>
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('4')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '4' ? 'red' : 'transparent', color: activeSection === '4' ? 'white' : 'black' }}
                                    >
                                        Laporan 3 Tahun Terakhir
                                    </ListGroup.Item></>
                                    ) : ''}
                                    {tipe==3 || tipe==4 ? (
                                        <><ListGroup.Item
                                            action onClick={() => setActiveSection('5')}
                                            style={{ borderRadius: '15px', backgroundColor: activeSection === '5' ? 'red' : 'transparent', color: activeSection === '5' ? 'white' : 'black' }}
                                        >
                                            Aspek Legalitas
                                        </ListGroup.Item><ListGroup.Item
                                            action onClick={() => setActiveSection('6')}
                                            style={{ borderRadius: '15px', backgroundColor: activeSection === '6' ? 'red' : 'transparent', color: activeSection === '6' ? 'white' : 'black' }}
                                        >
                                                Infrastruktur
                                            </ListGroup.Item><ListGroup.Item
                                            action onClick={() => setActiveSection('7')}
                                            style={{ borderRadius: '15px', backgroundColor: activeSection === '7' ? 'red' : 'transparent', color: activeSection === '7' ? 'white' : 'black' }}
                                        >
                                                Quality Management
                                            </ListGroup.Item></>
                                        
                                    ) : ''}
                                        <ListGroup.Item
                                        action onClick={() => setActiveSection('8')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '8' ? 'red' : 'transparent', color: activeSection === '8' ? 'white' : 'black' }}
                                    >
                                        Dokumen Proposal
                                    </ListGroup.Item>
                                    
                                    
                                    <ListGroup.Item
                                        action onClick={() => setActiveSection('0')}
                                        style={{ borderRadius: '15px', backgroundColor: activeSection === '0' ? 'red' : 'transparent', color: activeSection === '0' ? 'white' : 'black' }}
                                    >
                                        Review
                                    </ListGroup.Item>
                                </ListGroup>
                            </CSSTransition>
                        </TransitionGroup>
                    </Col>
                    <Col xs={9} className="p-4">
                        <Fade in={show} timeout={300}>
                            <div className="slide-up-enter-active">
                                {renderSection()}
                            </div>
                        </Fade>
                    </Col>
                </Row>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}

                {showConsentModal && <div>Modal Register</div>}

                    <Modal show={showConsentModal} style={{zIndex: '3500' }} >
                    <Modal.Header closeButton>
                        <Modal.Title>Registration Consent</Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ fontSize: '13px' }} className="p-5">
                        {registerError && (
                            <Alert variant="danger" style={{ padding: "10px", fontWeight: "bold" }}>
                                {registerError}
                            </Alert>
                        )}
                        <Form onSubmit={handleConsentSubmit}>
                            <div className="mb-4" style={{ fontSize: '18px' }}> 
                                <span>Dengan mengklik Setuju ini, calon mitra memberikan persetujuan kepada TELKOMSEL dan pihak ketiga yang ditunjuk TELKOMSEL dalam rangka pelaksanaan NGPP 3.0 ini untuk memperoleh, mengumpulkan, mengolah, menganalisis, menyimpan, menampilkan, mengungkapkan, menghapus data dan/atau data pribadi milik calon mitra sesuai dengan peraturan perundang-undangan yang berlaku. <br /><br />
                                Persetujuan ini diberikan oleh calon mitra secara sadar tanpa adanya paksaan dari pihak mana pun dan dapat digunakan sebagai pembuktian dikemudian hari (apabila diperlukan).</span>
                            </div>
                            <div className="text-end">
                                <Button variant="success" type="submit">
                                    Setuju
                                </Button>
                            </div>
                        </Form>
                    </Modal.Body>
                </Modal>
            </Container>
        </div>
    )
}

export default FormPengajuan;
