import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Alert, Form, Button, Row, Col, Container } from 'react-bootstrap';

const MAX_FILE_SIZE_MB = 50; // Maximum file size in MB

const FormLaporanTigaTahun = ({ uid, username }) => {

    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        keuangan_holding_2023: null,
        keuangan_holding_2022: null,
        keuangan_holding_2021: null,
        laba_rugi_2023: null,
        laba_rugi_2022: null,
        laba_rugi_2021: null,
        spt_2023: null,
        spt_2022: null,
        spt_2021: null,
        neraca_2023: null,
        neraca_2022: null,
        neraca_2021: null,
    });

    const [IsFileRequired, setIsFileRequired] = useState(true); 
    const [disabledForm, setdisabledForm] = useState(false); 

    useEffect(() => {
        fetch(`${process.env.REACT_APP_SERVER}/getLaporanTigaTahun`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ username })
        })
        .then(response => response.json())
        .then(data => {
            if(data.length > 0){
                setIsFileRequired(false);
                if(data[0].status=='PENDING' || data[0].status=='APPROVED'){
                    setdisabledForm(true); // Menjadikan Logo tidak wajib jika sudah ada
                }
            }
        })
        .catch(error => console.error('Error fetching data:', error));
    }, [username]);

    const handleChange = (e) => {
        const { name, files } = e.target;

        if (
            name.startsWith('keuangan_holding') ||
            name.startsWith('laba_rugi') ||
            name.startsWith('spt') ||
            name.startsWith('neraca')
        ) {
            if (files.length > 0) {
                const file = files[0];

                if (file.type !== 'application/pdf') {
                    Swal.fire({
                        icon: 'error',
                        title: 'File tidak valid',
                        text: 'Hanya file .pdf yang diperbolehkan!',
                    });
                    setFormData((prevData) => ({ ...prevData, [name]: null }));
                    return;
                }

                if (file.size > MAX_FILE_SIZE_MB * 1024 * 1024) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Ukuran file terlalu besar',
                        text: `Ukuran file tidak boleh lebih dari ${MAX_FILE_SIZE_MB} MB!`,
                    });
                    setFormData((prevData) => ({ ...prevData, [name]: null }));
                    return;
                }

                setFormData((prevData) => ({ ...prevData, [name]: file }));
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        const requiredFiles = [
            'keuangan_holding_2023',
            'laba_rugi_2023',
            'spt_2023',
            'neraca_2023',
        ];

        for (const fileName of requiredFiles) {
            if (!formData[fileName] && IsFileRequired==true) {
                Swal.fire({
                    icon: 'warning',
                    title: 'File tidak lengkap',
                    text: `File ${fileName} harus diunggah!`,
                });
                setLoading(false);
                return;
            }
        }

        const usernamePart = `${uid}_${username.split('@')[0]}`;
        const data = new FormData();
        data.append('username', username);

        Object.keys(formData).forEach((key) => {
            if (formData[key]) {
                const renamedFile = new File([formData[key]], `${usernamePart}_${key}.pdf`, {
                    type: formData[key].type,
                });
                data.append(key, renamedFile);
            }
        });

        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER}/uploadLaporanKeuangan`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                Swal.fire({
                    icon: 'success',
                    title: 'Data berhasil disimpan',
                    text: 'Data perusahaan berhasil disimpan',
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Gagal menyimpan data',
                    text: 'Gagal menyimpan data perusahaan',
                });
            }
        } catch (error) {
            console.error('Terjadi kesalahan:', error);
            Swal.fire({
                icon: 'error',
                title: 'Terjadi kesalahan',
                text: 'Terjadi kesalahan saat menyimpan data perusahaan',
            });
        }
        setLoading(false);
    };

    return (
        <div style={{ height: '70vh', overflowY: 'auto' }}>
            <p className="mb-4"><b>Informasi Data Perusahaan 3 Tahun Terakhir</b></p>
            <Alert key="info" variant="info">Semua file yang di upload harus berformat <b>.pdf</b> dengan max size per file 50MB</Alert>

            <Form onSubmit={handleSubmit}>
                <Container style={{ borderBottom: '0px solid #CCC' }} className="pb-4 mb-4">
                    <Row className='pb-2 mb-0'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>LAPORAN KEUANGAN HOLDING</span>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>LAPORAN LABA RUGI</span>
                        </Col>
                    </Row>

                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2023 <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="keuangan_holding_2023"
                                placeholder=""
                                style={{ width: '95%' }}
                                required={IsFileRequired}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2023 <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="laba_rugi_2023"
                                placeholder=""
                                style={{ width: '95%' }}
                                required={IsFileRequired}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2022</span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="keuangan_holding_2022"
                                placeholder=""
                                style={{ width: '95%' }}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2022 </span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="laba_rugi_2022"
                                placeholder=""
                                style={{ width: '95%' }}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2021</span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="keuangan_holding_2021"
                                placeholder=""
                                style={{ width: '95%' }}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2021 </span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="laba_rugi_2021"
                                placeholder=""
                                style={{ width: '95%' }}
                                disabled={disabledForm}
                                onChange={handleChange}
                            />
                        </Col>
                    </Row>

                    <Row className='pb-2 mt-4'>
                        <Col xs={6} className="px-0">
                            <span style={{ fontSize: '12px' }}>LAPORAN SPT</span>
                        </Col>
                        <Col xs={6} className="p-0">
                            <span style={{ fontSize: '12px' }}>LAPORAN NERACA</span>
                        </Col>
                    </Row>
                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2023 <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="spt_2023"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                required={IsFileRequired}
                                disabled={disabledForm}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2023 <span className="text-danger"><b>*</b></span></span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="neraca_2023"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                required={IsFileRequired}
                                disabled={disabledForm}
                            />
                        </Col>
                    </Row>
                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2022</span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="spt_2022"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                disabled={disabledForm}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2022 </span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="neraca_2022"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                disabled={disabledForm}
                            />
                        </Col>
                    </Row>
                    <Row className='pb-2 mb-0'>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2021</span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="spt_2021"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                disabled={disabledForm}
                            />
                        </Col>
                        <Col xs={1} className="px-0">
                            <span style={{ fontSize: '12px' }}>2021 </span>
                        </Col>
                        <Col xs={5} className="p-0">
                            <Form.Control
                                size="sm"
                                type="file"
                                name="neraca_2021"
                                placeholder=""
                                style={{ width: '95%' }}
                                onChange={handleChange}
                                disabled={disabledForm}
                            />
                        </Col>
                        <Col xs={6} className="pt-4 px-0"></Col>
                        <Col xs={6} className="pt-4 px-3 text-end">
                            <Button type="submit" className="bg-warning" style={{ border: '0px solid black' }}>Simpan</Button>
                        </Col>
                    </Row>
                </Container>
                {loading && (
                            <div className="overlay">
                                <div className="loading">Loading...</div>
                            </div>
                        )}
            </Form>
        </div>
    );
};

export default FormLaporanTigaTahun;
